import {
  MakeAPaymentBalance,
  MakeAPaymentPaymentSetup,
} from '@agria/paws/src/types';
import React, { createContext, useMemo, useReducer } from 'react';
import { useSyncContextToSessionStorage } from '@agria/utils';

export interface MakeAPaymentContextProps {
  makeAPaymentType?: 'lookup' | 'link';
  setMakeAPaymentType: (type: 'lookup' | 'link') => void;
  policyNumber?: string;
  setPolicyNumber: (policyNumber: string) => void;
  policyId?: string;
  setPolicyId: (policyId: string) => void;
  postalcodeOutcode?: string | undefined;
  setPostalcodeOutcode: (postcode: string) => void;
  balances?: MakeAPaymentBalance[];
  setUpBalances: (balances: MakeAPaymentBalance[]) => void;
  updateBalance: (
    policyIdToUpdate: string,
    policyData: MakeAPaymentPaymentSetup
  ) => void;
  paymentNumber?: number;
  setPaymentNumber: (number: number) => void;
  paymentJourneyNumber?: number;
  setPaymentJourneyNumber: (number: number) => void;
  wipeMakeAPaymentData: () => void;
}

interface PaymentState {
  makeAPaymentType: 'lookup' | 'link';
  policyNumber?: string;
  policyId?: string;
  postalcodeOutcode?: string;
  balances: MakeAPaymentBalance[];
  paymentNumber: number;
  paymentJourneyNumber: number;
  hasSet?: boolean;
}

type PaymentAction =
  | { type: 'SET_PAYMENT_TYPE'; payload: 'lookup' | 'link' }
  | { type: 'SET_POLICY_NUMBER'; payload: string }
  | { type: 'SET_POLICY_ID'; payload: string }
  | { type: 'SET_POSTCODE'; payload: string }
  | { type: 'SET_BALANCES'; payload: MakeAPaymentBalance[] }
  | {
      type: 'UPDATE_BALANCE';
      payload: { policyId: string; data: MakeAPaymentPaymentSetup };
    }
  | { type: 'SET_PAYMENT_NUMBER'; payload: number }
  | { type: 'SET_PAYMENT_JOURNEY_NUMBER'; payload: number }
  | { type: 'RESET_STATE' }
  | { type: 'SET_ALL'; payload: PaymentState }
  | { type: 'SET_HAS_SET'; payload: boolean };

const initialState: PaymentState = {
  makeAPaymentType: 'lookup',
  balances: [],
  paymentNumber: 1,
  paymentJourneyNumber: 0,
  hasSet: false,
};

const paymentReducer = (
  state: PaymentState,
  action: PaymentAction
): PaymentState => {
  switch (action.type) {
    case 'SET_ALL':
      return { ...state, ...action.payload };
    case 'SET_HAS_SET':
      return { ...state, hasSet: action.payload };
    case 'SET_PAYMENT_TYPE':
      return { ...state, makeAPaymentType: action.payload };
    case 'SET_POLICY_NUMBER':
      return { ...state, policyNumber: action.payload };
    case 'SET_POLICY_ID':
      return { ...state, policyId: action.payload };
    case 'SET_POSTCODE':
      return { ...state, postalcodeOutcode: action.payload };
    case 'SET_BALANCES':
      return { ...state, balances: action.payload };
    case 'UPDATE_BALANCE':
      return {
        ...state,
        balances: state.balances.map((balance) =>
          balance?.policyId === action.payload.policyId
            ? {
                ...balance,
                paymentFrameUrl:
                  action.payload.data.CybersourceFrameURL || undefined,
                amountDue: action.payload.data.TotalAmount || 0,
                policyNumber: action.payload.data.PolicyNumber || undefined,
                petName: action.payload.data.PetName || undefined,
              }
            : balance
        ),
      };
    case 'SET_PAYMENT_NUMBER':
      return { ...state, paymentNumber: action.payload };
    case 'SET_PAYMENT_JOURNEY_NUMBER':
      return { ...state, paymentJourneyNumber: action.payload };
    case 'RESET_STATE':
      return initialState;
    default:
      return state;
  }
};

export const MakeAPaymentContext =
  createContext<Partial<MakeAPaymentContextProps>>(initialState);

MakeAPaymentContext.displayName = 'MakeAPaymentContext';

export const MakeAPaymentProvider = ({ children }: React.PropsWithChildren) => {
  const [state, dispatch] = useReducer(paymentReducer, initialState);

  // Sync with session storage
  useSyncContextToSessionStorage('makePayment', {
    state: state as any,
    dispatch,
  });

  // Action creators
  const setMakeAPaymentType = React.useCallback((type: 'lookup' | 'link') => {
    dispatch({ type: 'SET_PAYMENT_TYPE', payload: type });
  }, []);

  const setPolicyNumber = React.useCallback((policyNumber: string) => {
    dispatch({ type: 'SET_POLICY_NUMBER', payload: policyNumber });
  }, []);

  const setPolicyId = React.useCallback((policyId: string) => {
    dispatch({ type: 'SET_POLICY_ID', payload: policyId });
  }, []);

  const setPostalcodeOutcode = React.useCallback((postcode: string) => {
    dispatch({ type: 'SET_POSTCODE', payload: postcode });
  }, []);

  const setUpBalances = React.useCallback((balances: MakeAPaymentBalance[]) => {
    dispatch({ type: 'SET_BALANCES', payload: balances });
  }, []);

  const updateBalance = React.useCallback(
    (policyIdToUpdate: string, policyData: MakeAPaymentPaymentSetup) => {
      if (policyIdToUpdate && policyData) {
        dispatch({
          type: 'UPDATE_BALANCE',
          payload: { policyId: policyIdToUpdate, data: policyData },
        });
      }
    },
    []
  );

  const setPaymentNumber = React.useCallback((number: number) => {
    dispatch({ type: 'SET_PAYMENT_NUMBER', payload: number });
  }, []);

  const setPaymentJourneyNumber = React.useCallback((number: number) => {
    dispatch({ type: 'SET_PAYMENT_JOURNEY_NUMBER', payload: number });
  }, []);

  const wipeMakeAPaymentData = React.useCallback(() => {
    dispatch({ type: 'RESET_STATE' });
    sessionStorage.removeItem('Agria@makePayment');
  }, []);

  const contextValues = useMemo(
    () => ({
      makeAPaymentType: state.makeAPaymentType,
      setMakeAPaymentType,
      policyNumber: state.policyNumber,
      setPolicyNumber,
      policyId: state.policyId,
      setPolicyId,
      postalcodeOutcode: state.postalcodeOutcode,
      setPostalcodeOutcode,
      balances: state.balances,
      setUpBalances,
      updateBalance,
      paymentNumber: state.paymentNumber,
      setPaymentNumber,
      paymentJourneyNumber: state.paymentJourneyNumber,
      setPaymentJourneyNumber,
      wipeMakeAPaymentData,
    }),
    [
      state,
      setMakeAPaymentType,
      setPolicyNumber,
      setPolicyId,
      setPostalcodeOutcode,
      setUpBalances,
      updateBalance,
      setPaymentNumber,
      setPaymentJourneyNumber,
      wipeMakeAPaymentData,
    ]
  );

  return (
    <MakeAPaymentContext.Provider value={contextValues}>
      {children}
    </MakeAPaymentContext.Provider>
  );
};
