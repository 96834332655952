import { GatsbyBrowser } from 'gatsby';

declare global {
  interface Window {
    makeAPaymentReferrer?: string;
  }
}

export { wrapRootElement } from './gatsby-shared';

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({
  location,
  prevLocation,
}) => {
  const eventData = {
    pathname: location.pathname.replace(/\//g, ''),
  };
  const event = new CustomEvent('pageChange', { detail: eventData });
  window.dispatchEvent(event);
  window.makeAPaymentReferrer = prevLocation?.pathname.replace(/\//g, '');
};
