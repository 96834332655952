import type { GatsbySSR, GatsbyBrowser } from 'gatsby';
import React from 'react';
import { GlobalLoaderProvider } from './src/context/GlobalLoaderContext';
import { MakeAPaymentProvider } from './src/context/MakeAPaymentContext';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] &
  GatsbySSR['wrapRootElement'] = ({ element }) => (
  <GlobalLoaderProvider>
    <MakeAPaymentProvider>{element}</MakeAPaymentProvider>
  </GlobalLoaderProvider>
);

export const onRenderBody: GatsbySSR['onRenderBody'] = ({
  setHeadComponents,
}) => {
  setHeadComponents([
    <script
      key="partytown-vanilla-config"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `partytown = { debug: false }`,
      }}
    />,
  ]);
};
