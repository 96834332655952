import React, { createContext, useMemo } from 'react';

export interface GlobalLoaderContextProps {
  heading?: string;
  subHeading?: string;
  showLoader: boolean;
  setShowLoader: (showLoader: boolean) => void;
  setLoaderHeadings: (heading: string, subHeading: string) => void;
}

const intialState: Partial<GlobalLoaderContextProps> = {
  heading: '',
  subHeading: '',
  showLoader: false,
};

export const GlobalLoaderContext =
  createContext<Partial<GlobalLoaderContextProps>>(intialState);

GlobalLoaderContext.displayName = 'GlobalLoaderContext';

export const GlobalLoaderProvider = ({ children }: React.PropsWithChildren) => {
  const [showLoader, setShowLoader] = React.useState(false);
  const [heading, setHeading] = React.useState('');
  const [subHeading, setSubheading] = React.useState('');

  const setLoaderHeadings = useMemo(
    () => (newHeading: string, newSubheading: string) => {
      setHeading(newHeading);
      setSubheading(newSubheading);
    },
    [setHeading, setSubheading]
  );

  const contextValues = useMemo(
    () => ({
      heading,
      subHeading,
      showLoader,
      setShowLoader,
      setLoaderHeadings,
    }),
    [heading, subHeading, showLoader, setShowLoader, setLoaderHeadings]
  );

  return (
    <GlobalLoaderContext.Provider value={contextValues}>
      {children}
    </GlobalLoaderContext.Provider>
  );
};
